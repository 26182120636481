import React from "react";

import "./client.scss";
import Image from "../../../asset/image";

const client = () => {
  return (
    <div className="client flex">
      <div className="client__content flex__row">
        <div className="client__content__text flex__column">
          <h3>
            Ils nous font confiance
          </h3>
          <p>
            Au cœur de notre réussite, ces entreprises nous ont fait confiance, témoignant de
            l'efficacité et de la fiabilité de nos solutions. Notre collaboration avec des acteurs majeurs tels que
            SNCF et iMSA illustre parfaitement notre engagement à fournir des services de qualité, adaptés aux besoins
            spécifiques de chaque client. Cet écosystème diversifié de partenaires s'appuie sur notre expertise pour
            naviguer dans le paysage complexe de leur secteur, affirmant ainsi notre rôle de pilier dans leur
            transformation et succès continus.
          </p>
        </div>

        <div className="client__content__section flex__row">
          <img src={Image.Sncf} alt="SNCF" />
          <img src={Image.Imsa} alt="iMSA" />
        </div>
      </div>
    </div>
  );
};

export default client;
