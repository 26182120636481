import React from 'react';
import './construct.scss';
import {Construction} from "@mui/icons-material";

function ConstructSection() {

    return (
        <div className="construction">
            <Construction></Construction>
            <h1>En construction</h1>
            <h2>(Patience, on arrive très bientôt !)</h2>
        </div>
    )

}

export default ConstructSection;