import React, { useEffect } from "react";
import { Construct } from "../section/index";

function ConstructiopPage() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, []);

    return <Construct />;
}

export default ConstructiopPage;
