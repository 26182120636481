import React, {useState} from 'react';
import Image from "../../../asset/image";
import DetailProfile from '../DetailProfileSection/DetailProfileSection';

const ProfileSection = () => {
    const [selectedProfile, setSelectedProfile] = useState(null);

    const profiles = [
        {
            id: 1,
            name: 'NZA',
            experience: '2 ans ½',
            description: 'Fullstack avec une expertise en NodeJS, VueJS, ReactJS, ainsi qu’en Java, JEE, Python. Expérimenté en DevOps, y compris le Cloud AWS, Azure Devops, la mise en place de CI/CD et Terraform. Familiarisé avec des méthodes agiles telles que Agile-Scrum, et une solide compréhension de l’architecture système.',
            resume: 'Profil Fullstack',
            image: Image.AvatarNidal,
            skills: [
                'NodeJS',
                'VueJS',
                'ReactJS',
                'Java / JEE / SpringBoot',
                'Python',
                'Terraform',
                'Cloud AWS',
                'Azure Devops',
                'CI/CD',
                'Modélisation (UML, Merise...)',
                'Architecture (Micro-services, MVC, Clean Code)',
                'Agile-Scrum',
                'Innovation',
            ]
        },
        {
            id: 2,
            name: 'ALN',
            experience: '2 ans',
            description: 'Fullstack spécialisé en NodeJS, VueJS, ReactJS, avec des compétences supplémentaires en C, C++ et Python. Compétences en DevOps sur le Cloud AWS et Azure Devops, et mise en place de CI/CD. Approche méthodologique comprenant Agile-Scrum, l’innovation, le Design Thinking et le Design Process, avec un accent particulier sur les besoins métiers (UX/UI, Figma, Responsive).',
            resume: 'Profil Fullstack',
            image: Image.AvatarAyinde,
            skills: [
                'NodeJS',
                'VueJS',
                'ReactJS',
                'C / C++',
                'Python',
                'Cloud AWS',
                'Azure Devops',
                'CI/CD',
                'Agile-Scrum',
                'Innovation',
                'Design Thinking / Design Process',
                'Figma',
            ]
        },
        {
            id: 3,
            name: 'CDE',
            experience: '24 ans',
            description: 'Direction informatique avec une longue expérience en gestion de projets, pilotage d’activités, stratégie et management. Expertise dans le domaine de la data, l’urbanisation, le schéma directeur et la stratégie, ainsi que l’onboarding d’équipes. Compétences en méthodologies Agile, SAFe, Lean et innovation.',
            resume: 'Profil Direction Informatique',
            image: Image.AvatarCyprien,
            skills: [
                'Direction de projet',
                'Pilotage d’activité',
                'Stratégie',
                'Management',
                'Data',
                'Urbanisation',
                'Schéma Directeur',
                'Stratégie',
                'Onboarding d’équipes',
                'Innovation',
                'Agile / SAFe / Lean',
            ]
        }
    ];

    const selectProfile = (profile) => {
        setSelectedProfile(profile);
    };

    return (
        <div className="section-profile">
            <div className="section-profile__intro">
                <div className="section-profile__intro__left">
                    <img src={Image.BannerProfile} alt='todo'/>
                </div>
                <div className="section-profile__intro__right">
                    <h2 className="section-profile__intro__title">Découvrez nos profils</h2>
                    <p className="section-profile__intro__description">
                        Nous avons une équipe de profils variés, chacun avec des compétences spécifiques. Découvrez-les
                        ci-dessous.
                    </p>
                </div>
            </div>
            <div className="section-profile__list">
                {profiles.map((profile) => (
                    <div key={profile.id} className="profile-card" onClick={() => selectProfile(profile)}>
                        <img src={profile.image} alt={profile.name} className="profile-card__image"/>
                        <h3 className="profile-card__name">{profile.name}</h3>
                        <p className="profile-card__experience">{profile.experience}</p>
                        <p className="profile-card__description">{profile.resume}</p>
                    </div>
                ))}
            </div>
            {selectedProfile && <DetailProfile profile={selectedProfile}/>}
        </div>
    );
};

export default ProfileSection;