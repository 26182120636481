import React from 'react';

const DetailProfile = ({ profile }) => {
    return (
        <div className="detail-profile">
            <div className="detail-profile__left">
                <img src={profile.image} alt={`Image de ${profile.name}`} className="detail-profile__left__image"/>
                <h2 className="detail-profile__left__title">{profile.name}</h2>
                <p className="detail-profile__left__description">{profile.description}</p>
                <button className="btn-purple">Contact</button>
            </div>
            <div className="detail-profile__right">
                <h3 className="detail-profile__right__title">Compétences</h3>
                <ul className="detail-profile__right__skills">
                    {profile.skills.map((skill, index) => (
                        <li key={index} className="detail-profile__right__skill-item">{skill}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default DetailProfile;
