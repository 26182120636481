import React, { useEffect } from "react";
import { ContactSection } from "../section/index";

function ContactPage() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return <ContactSection />;
}

export default ContactPage;
