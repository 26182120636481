import React from "react";
import "./bandeau.scss";
import {Link} from "react-router-dom";

const bandeau = () => {
  return (
    <div className="band">
      <div className="band__service flex__column">
        <div className="band__service__img"></div>
        <div className="band__service__content flex">
          <div className="band__service__content--title flex">
            <h2>
              Nos
              <br />
              services
            </h2>
          </div>
          <div className="line"></div>
          <div className="band__service__content--text flex__column">
            <h4>Accélérez avec Cyde</h4>
            <p>
              Passez à la vitesse supérieure ! Cliquez ici pour découvrir nos
              services et booster votre transformation digitale.
            </p>
            <Link to='/services' className='btn-purple'>
              Découvrez nos offres de services
            </Link>
          </div>
        </div>
      </div>

      <div className="band__product flex">
        <div className="band__product__content flex__column">
          <h2>
            Nos
            <br />
            produits
          </h2>
          <div className="line"></div>
          <h4>Découvrez Nos Solutions Innovantes</h4>
          <p>
            Explorez nos produits exclusifs, soigneusement sélectionnés pour
            offrir des solutions inédites. Soyez à l’affut des dernières
            innovations Cyde.
          </p>
          <Link to='/produits' className='btn-purple'>
            Explorez maintenant
          </Link>
        </div>
        <div className="band__product__img"></div>
      </div>
    </div>
  );
};

export default bandeau;
