import React from "react";
import "./presentation.scss";

import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {LightbulbOutlined, PsychologyAltOutlined, WorkOutline} from "@mui/icons-material";

const presentation = () => {
  return (
    <div className="pres">
      <div className="pres__container flex__column">
        <h1>
          Optimisez Votre Transformation Digitale
          <span className="highlight"> pour un Succès Durable</span>
        </h1>
        <div className="pres__content flex">
          <div className="pres__content--card flex__column">
            <PsychologyAltOutlined />
            <h4>Conseil</h4>
            <p>
              Laissez Cyde vous guider dans vos projets de transformation
              digitale, optimisant chaque étape pour un succès durable.
            </p>
            {/* <button className="detail-purple flex">
              En savoir plus <ArrowForwardIcon />{" "}
            </button> */}
          </div>
          <div className="pres__content--card flex__column">
            <LightbulbOutlined />
            <h4>Innovation</h4>
            <p>
              Identifiez les meilleurs leviers pour gagner en temps et en budget
              avec Cyde.
            </p>
            {/* <button className="detail-purple flex">
              En savoir plus <ArrowForwardIcon />{" "}
            </button> */}
          </div>
          <div className="pres__content--card flex__column">
            <WorkOutline />
            <h4>Valeur Métier</h4>
            <p>
              Faites preuve d'expertise en management responsable, innovez et
              maximisez la valeur métier.
            </p>
            {/* <button className="detail-purple flex">
              En savoir plus <ArrowForwardIcon />{" "}
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default presentation;
