import React, { useEffect } from "react";
import { LegalNotice } from "../section/index";

const legalNoticePage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return <LegalNotice />;
};

export default legalNoticePage;
