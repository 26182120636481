import React, { useState, useRef } from "react";
import Joinus from "../../asset/image/joinus.avif";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Image from "../../asset/image.js";

import EcoSection from "./ProductSection/ecoSection";
import IoviaSection from "./ProductSection/ioviaSection";
import CoInnovSection from "./ProductSection/coInnovSection";
import FlashReportSection from "./ProductSection/flashReportSection";
import RoadmapSection from "./ProductSection/roadmapSection";

import "./ourProduct.scss";

const ourProduct = () => {
  const [indexProduct, setIndexProduct] = useState(0);
  const scrollToProduct = useRef(null);

  const toggleProductTabs = (index) => {
    setIndexProduct(index);
  };

  return (
    <div className="product flex__column">
      <div
        className={
          indexProduct === 0 ? "indicator--hidden" : "indicator-up flex"
        }
        onClick={() => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }}
      >
        <ArrowUpwardIcon />
      </div>

      <div className="product__presentation flex__column">
        <h1>
            Innovations et <span className="highlight">Solutions Éco-Responsables</span> pour une
            <span className="highlight"> Transformation Digitale</span> Réussie
        </h1>
        <p>
            Découvrez notre gamme de produits innovants, conçus pour répondre aux défis modernes de gestion de projet,
            d'orientation professionnelle dans l'IT, de navigation internet éco-responsable, et de co-création de
            projets d'innovation. Chaque solution est pensée pour faciliter votre quotidien tout en mettant l'accent
            sur la responsabilité sociale et environnementale. Explorez nos produits et trouvez les outils adaptés pour
            transformer positivement votre approche professionnelle et personnelle.
        </p>
        <div className="product__presentation__content flex__row">
          <div className="product__presentation__content--card flex__column">
            <img
              src={Image.LogoFlashReport}
              alt="produit cyde"
              onClick={() => {
                window.scrollTo({
                  top: scrollToProduct.current.offsetTop - 100,
                  behavior: "smooth",
                });
                toggleProductTabs(1);
              }}
              className={indexProduct === 1 ? "product--icon product--selected" : "product--icon"}
            />
            <h3>Flash Report</h3>
            <p>
              {" "}
                Optimisez votre gestion de projet avec Flash Report : IA et algorithmes
                pour une efficacité accrue, adapté à toute méthodologie.
            </p>
          </div>
          <div className="product__presentation__content--card flex__column">
            <img
              src={Image.LogoIovia}
              alt="produit cyde"
              onClick={() => {
                window.scrollTo({
                  top: scrollToProduct.current.offsetTop - 100,
                  behavior: "smooth",
                });
                toggleProductTabs(2);
              }}
              className={indexProduct === 2 ? "product--icon product--selected" : "product--icon"}
            />
            <h3>Iovia</h3>
            <p>
              {" "}
                Découvrez IOVIA, l'application gratuite pour trouver votre métier idéal dans
                l'IT selon vos compétences et profil, disponible sur Google Play.
            </p>
          </div>
          <div className="product__presentation__content--card flex__column">
            <img
              src={Image.LogoEcoOn}
              alt="produit cyde"
              onClick={() => {
                window.scrollTo({
                  top: scrollToProduct.current.offsetTop - 100,
                  behavior: "smooth",
                });
                toggleProductTabs(3);
              }}
              className={indexProduct === 3 ? "product--icon product--selected" : "product--icon"}
            />
            <h3>Bouton éco</h3>
            <p>
              {" "}
                Réduisez votre empreinte écologique avec le bouton éco-responsable
                de Cyde, optimisant la navigation sans compromettre la qualité.
            </p>
          </div>
          <div className="product__presentation__content--card flex__column">
            <img
              src={Image.LogoCoInnov}
              alt="produit cyde"
              onClick={() => {
                window.scrollTo({
                  top: scrollToProduct.current.offsetTop - 100,
                  behavior: "smooth",
                });
                toggleProductTabs(4);
              }}
              className={indexProduct === 4 ? "product--icon product--selected" : "product--icon"}
            />
            <h3>Co-innovation</h3>
            <p>
              {" "}
                Co-Innovation, votre plateforme de co-création pour partager idées
                et connecter des acteurs autour de projets d'innovation ambitieux.
            </p>
          </div>
          <div className="product__presentation__content--card flex__column">
            <img
              src={Image.LogoCyde}
              alt="produit cyde"
              onClick={() => {
                window.scrollTo({
                  top: scrollToProduct.current.offsetTop - 100,
                  behavior: "smooth",
                });
                toggleProductTabs(5);
              }}
              className={indexProduct === 5 ? "product--icon product--selected" : "product--icon"}
            />
            <h3>Roadmap produit</h3>
            <p>
              {" "}
                Découvrez nos futures innovations promouvant un management responsable,
                pour transformer positivement votre organisation.
            </p>
          </div>
        </div>
      </div>

      <div ref={scrollToProduct}></div>
      <div
        className={
          indexProduct === 1 ? "product__desc flex" : "hidden__product"
        }
      >
        <FlashReportSection />
      </div>
      <div
        className={
          indexProduct === 2 ? "product__desc flex" : "hidden__product"
        }
      >
        <IoviaSection />
      </div>
      <div
        className={
          indexProduct === 3 ? "product__desc flex" : "hidden__product"
        }
      >
        <EcoSection />
      </div>
      <div
        className={
          indexProduct === 4 ? "product__desc flex" : "hidden__product"
        }
      >
        <CoInnovSection />
      </div>
      <div
        className={
          indexProduct === 5 ? "product__desc flex" : "hidden__product"
        }
      >
        <RoadmapSection />
      </div>
    </div>
  );
};

export default ourProduct;
