import React, { useEffect } from "react";
import { OurProduct } from "../section/index";

const ourProductPage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return <OurProduct />;
};

export default ourProductPage;
