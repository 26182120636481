import React, { useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import InfoIcon from "@mui/icons-material/Info";

const eco = () => {
  const [state, setState] = React.useState({
    open: true,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  const [toggleSwitched, setToggleSwitched] = useState(false);
  const [empreinte_carbone_gCO2e_before, setempreinte_carbone_gCO2e_before] =
    useState(0);
  const [empreinte_carbone_gCO2e_eco, setempreinte_carbone_gCO2e_eco] =
    useState(0);
  const [empreinte_carbone_gCO2e_saved, setempreinte_carbone_gCO2e_saved] =
    useState(0);

  useEffect(() => {
    const ressources = performance.getEntriesByType("resource");
    console.log(ressources);
    let sized = 0;
    ressources.forEach((ressource) => {
      // Affiche la taille de la requête si disponible
      if (ressource.encodedBodySize) {
        sized += ressource.encodedBodySize;
      }
    });
    // Conversion de la taille totale en octets en kilooctets (ko)
    let sized_ko = sized / 1024;
    // L'empreinte carbone moyenne pour le transfert de données sur internet est d'environ 0.06g CO2e par ko de données
    // Source (hypothétique, pour l'exemple): "The Shift Project, 2021"
    let empreinte_carbone_gCO2e = sized_ko * 0.06;
    setempreinte_carbone_gCO2e_before(empreinte_carbone_gCO2e);
  }, []);

  //Changement de Font
  const changeFont = () => {
    const allElements = document.querySelectorAll("*");

    if (!toggleSwitched) {
      // changement du font Bold
      allElements.forEach((element) => {
        const currentStyle = window.getComputedStyle(element);

        if (currentStyle.fontFamily.includes("Graphik Bold")) {
          element.style.fontFamily = "Inter Bold";
        }
      });
      // changement du font Bold
      allElements.forEach((element) => {
        const currentStyle = window.getComputedStyle(element);

        if (currentStyle.fontFamily.includes("Graphik Medium")) {
          element.style.fontFamily = "Inter Medium";
        }
      });
      // changement du font Bold
      allElements.forEach((element) => {
        const currentStyle = window.getComputedStyle(element);

        if (currentStyle.fontFamily.includes("Graphik Semibold")) {
          element.style.fontFamily = "Inter Semibold";
        }
      });
      // changement du font Bold
      allElements.forEach((element) => {
        const currentStyle = window.getComputedStyle(element);

        if (currentStyle.fontFamily.includes("Graphik Regular")) {
          element.style.fontFamily = "Inter Regular";
        }
      });
    } else {
      // changement du font Bold
      allElements.forEach((element) => {
        const currentStyle = window.getComputedStyle(element);

        if (currentStyle.fontFamily.includes("Inter Bold")) {
          element.style.fontFamily = "Graphik Bold";
        }
      });
      // changement du font Bold
      allElements.forEach((element) => {
        const currentStyle = window.getComputedStyle(element);

        if (currentStyle.fontFamily.includes("Inter Medium")) {
          element.style.fontFamily = "Graphik Medium";
        }
      });
      // changement du font Bold
      allElements.forEach((element) => {
        const currentStyle = window.getComputedStyle(element);

        if (currentStyle.fontFamily.includes("Inter Semibold")) {
          element.style.fontFamily = "Graphik Semibold";
        }
      });
      // changement du font Bold
      allElements.forEach((element) => {
        const currentStyle = window.getComputedStyle(element);

        if (currentStyle.fontFamily.includes("Inter Regular")) {
          element.style.fontFamily = "Graphik Regular";
        }
      });
    }
  };

  const changeImgPath = () => {
    // Changement de chemin img
    const images = document.querySelectorAll("img");

    // if (!toggleSwitched) {
    //   images.forEach((img) => {
    //     img.src = img.src.replace(".", "-min.");
    //   });
    // } else {
    //   images.forEach((img) => {
    //     img.src = img.src.replace("-min.", ".");
    //   });
    // }
  };

  const clickEcobtn = () => {
    setToggleSwitched(!toggleSwitched);
    toggleSwitched === true
      ? sessionStorage.setItem("eco-mode", "true")
      : sessionStorage.setItem("eco-mode", "false");

    changeFont();
    changeImgPath();

    let empreinte_carbone_gCO2e = 0;

    if (!toggleSwitched) {
      const ressources = performance.getEntriesByType("resource");
      let sized = 0;
      ressources.forEach((ressource) => {
        // Affiche la taille de la requête si disponible
        if (ressource.encodedBodySize) {
          sized += ressource.encodedBodySize;
        }
      });
      // Conversion de la taille totale en octets en kilooctets (ko)
      let sized_ko = sized / 1024;
      // L'empreinte carbone moyenne pour le transfert de données sur internet est d'environ 0.06g CO2e par ko de données
      // Source (hypothétique, pour l'exemple): "The Shift Project, 2021"
      empreinte_carbone_gCO2e = sized_ko * 0.06;
      setempreinte_carbone_gCO2e_eco(empreinte_carbone_gCO2e);
      setempreinte_carbone_gCO2e_saved(
        empreinte_carbone_gCO2e_before - empreinte_carbone_gCO2e
      );
    }
  };

  return (
    <>
      <div class="flex eco-wrapper">
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={toggleSwitched}
          autoHideDuration={6000}
          message={`Votre empreinte carbone en mode éco : ${empreinte_carbone_gCO2e_eco.toFixed(
            2
          )} gCO2e`}
          key={vertical + horizontal}
          onClose={handleClose}
          action={action}
          sx={{ width: { xs: 90, sm: 0 } }}
        />
        <div class="eco-info">
          <InfoIcon />
          <div class="eco-info-content">
            <p>Un geste pour la nature !</p>
            <br />
            <p>
              Ceci est le bouton <b>éco-responsable</b> imaginé par Cyde .
            </p>
            <br />
            <p>
              En effet, en cliquant sur ce bouton, vous pouvez choisir de vous
              passer de certains éléments graphiques.
            </p>
            <br />
            <p>
              Vous pouvez ainsi réduire votre consommation de bande passante et
              donc votre empreinte écologique.
            </p>
            <i></i>
          </div>
        </div>
        <input
          type="checkbox"
          onClick={() => {
            clickEcobtn();
          }}
          className="eco-btn"
        />
      </div>
    </>
  );
};

export default eco;
