import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import LogoCyde from "../../asset/image/logo_cyde.svg";
import Eco from "../../utils/eco";

import "./nav.scss";

const Nav = () => {
  const [mobileState, setMobileState] = useState(false);

  return (
    <nav className={`${mobileState ? "nav-sticky" : ""}`}>
      <div className="logo">
        <Link to="/">
          <img src={LogoCyde} alt="Logo" height={50} width={50} />
        </Link>
        <Eco />
      </div>
      <div>
        <ul className={mobileState ? "navbar active" : "navbar"}>
          <li>
            <Link to="/" onClick={() => setMobileState(false)}>
                Accueil
            </Link>
          </li>
          <li>
            <Link to="/produits" onClick={() => setMobileState(false)}>
              Nos produits
            </Link>
          </li>
          <li>
            <Link to="/services" onClick={() => setMobileState(false)}>
              Nos services
            </Link>
          </li>
          <li>
            <Link to="/construction" onClick={() => setMobileState(false)}>
              À propos
            </Link>
          </li>
          {/*
          <li>
            <Link to="/#" onClick={() => setMobileState(false)}>
              Blog
            </Link>
          </li>
          <li>
            <Link to="/#" onClick={() => setMobileState(false)}>
              Recrutement
            </Link>
          </li>
          */}
          <li>
            <Link
              to="/contact"
              onClick={() => setMobileState(false)}
              className="btn-purple"
            >
              Contact
            </Link>
          </li>
        </ul>
      </div>
      <div className="mobile" onClick={() => setMobileState(!mobileState)}>
        {!mobileState ? (
          <MenuIcon className="mobile--icon" style={{ fontSize: 30 }} />
        ) : (
          <CloseIcon className="mobile--icon" style={{ fontSize: 30 }} />
        )}
      </div>
    </nav>
  );
};
export default Nav;
