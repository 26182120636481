import React from "react";

import Joinus from "../../../asset/image/joinus.avif";
import Sncf from "../../../asset/image/sncf.avif";
import {Assignment, Hub, Psychology} from "@mui/icons-material";
import Image from "../../../asset/image";

const flashReportSection = () => {
  return (
    <div className="productSection flex__column">
      <div className="productSection__band">
        <div className="productSection__band__content flex__column">
          <h4>Flash Report</h4>
          <p>
            Optimisez votre gestion de projet avec Flash Report : IA et algorithmes
            pour une efficacité accrue, adapté à toute méthodologie.
          </p>
          <a href="https://flash-report.com" className="btn-purple">
            En savoir plus
          </a>
        </div>
      </div>

      <div className="productSection__info flex__column">
        <h3>
          {" "}
          Flash Report : <span className="highlight">La Révolution</span><br/> de la Gestion de Projet
        </h3>
        <div className="info__content flex">
          <div className="info__content--img flex">
            <img src={Image.ArgFlashReport} alt="join us" />
          </div>
          <div className="info__content--text flex__column">
            <p>
              Flash Report incarne l'avant-garde de la gestion de projet grâce à son intégration poussée d'intelligence
              artificielle et d'algorithmes sophistiqués. Cette plateforme se distingue par sa capacité à s'adapter à
              une multitude de méthodologies de projet, qu'il s'agisse d'Agile, de Waterfall ou de Kanban. Destinée aux
              chefs de projet, directeurs de projet, et aux DSI, Flash Report transforme radicalement l'approche de la
              gestion de projet, en facilitant des décisions stratégiques rapides et éclairées, tout en améliorant
              l'efficacité et la performance globale des projets.
            </p>
            <div className="info__content--text__svg flex__column">
              <div className="svg__content flex__row">
                <Assignment />
                <p>Gestion de projet</p>
              </div>
              <div className="svg__content flex__row">
                <Psychology />
                <p>Intelligence Artificielle</p>
              </div>
              <div className="svg__content flex__row">
                <Hub />
                <p>Méthodologies</p>
              </div>
            </div>
            <button className="btn-purple" onClick={() => window.location.href="https://flash-report.com"}>En savoir plus</button>
          </div>
        </div>
      </div>

      {/*
      <div className="productSection__cta flex">
        <div className="productSection__cta--text flex__column">
          <div className="flex__row productSection__cta--text__logo">
            <img src={Sncf} alt="client" />
            <h3>Bouton éco</h3>
          </div>
          <h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h4>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore magna aliqua. Lorem ipsum dolor
            sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore magna aliqua.
          </p>
          <a href="#" className="btn-purple">
            Button
          </a>
        </div>
        <div className="productSection__cta--img"></div>
      </div>
      */}
    </div>
  );
};

export default flashReportSection;
