import React from "react";

import {CorporateFare, Lightbulb, Transform} from "@mui/icons-material";
import Image from "../../../asset/image";

const roadmapSection = () => {
  return (
    <div className="productSection flex__column">
      <div className="productSection__band">
        <div className="productSection__band__content flex__column">
          <h4>Roadmap</h4>
          <p>
            Découvrez nos futures innovations promouvant un management responsable,
            pour transformer positivement votre organisation.
          </p>
        </div>
      </div>

      <div className="productSection__info flex__column">
        <h3>
          {" "}
          Vers un <span className="highlight">Management Responsable</span> avec Nos Innovations
        </h3>
        <div className="info__content flex">
          <div className="info__content--img flex">
            <img src={Image.ArgRoadmap} alt="join us" />
          </div>
          <div className="info__content--text flex__column">
            <p>
              Nous sommes à l'aube d'une nouvelle ère dans laquelle le management responsable prend une place centrale
              dans le développement des organisations. Nos roadmaps de produits sont le reflet de cet engagement,
              proposant des innovations conçues pour encourager et faciliter un management qui non seulement respecte
              les principes du développement durable, mais qui vise également à transformer positivement les structures
              organisationnelles. Découvrez comment nos futures innovations peuvent aider votre entreprise à adopter des
              pratiques de management plus responsables et efficaces.
            </p>
            <div className="info__content--text__svg flex__column">
              <div className="svg__content flex__row">
                <CorporateFare />
                <p>Management responsable</p>
              </div>
              <div className="svg__content flex__row">
                <Lightbulb />
                <p>Transformation digitale</p>
              </div>
              <div className="svg__content flex__row">
                <Transform />
                <p>Approche disruptive</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default roadmapSection;
