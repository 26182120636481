import React from "react";
import "./legalNotice.scss";

const LegalNoticeSection = () => {
  return (
    <div>
      <div className="legalNotice">
        <h1 className="legalNotice__title">Mentions Légales</h1>

        <h2 className="legalNotice__subtitle">A. Site flash-report.com</h2>
        <p className="legalNotice__content">
          Propriétaire : Société CYDE, SAS au capital de 1000€, immatriculation
          N° 852 376 607 au RCS de Lille Métropole
          <br />
          <br />
          Siège Social : CYDE – 679 Avenue de la République – 59800 LILLE
          <br />
          <br />
          Contact : contact@cyde.fr
          <br />
          <br />
          Site créé et publié par Cyde, hébergé chez LWS (site web : www.lws.fr ; adresse : 10 Rue de Penthièvre, 75008 Paris)
        </p>

        <h2 className="legalNotice__subtitle">
          B. Conditions générales d’utilisation.
        </h2>
        <p className="legalNotice__content">
          L'accès, l'utilisation et les services qu'offre le site www.cyde.fr implique l’acceptation pleine et entière
          des conditions générales d’utilisation. Ces conditions sont susceptibles d'évoluer avec le développement de
          l'entreprise, les utilisateurs sont invités à les consulter régulièrement.
          <br />
          <br />
          La société Cyde effectue les mises à jour du site dans la perspective de l'accessibilité maximale de ses utilisateurs.
          Elle ne pourra être tenue responsable de son indisponibilité, pour quelque motif que ce soit.
          <br />
          <br />
          Le site www.cyde.fr présente de façon générale l'entreprise Cyde : sens, modèle, offres, valeurs, recrutement, clientèle.
          Les informations présentées sont proposées à titre d'information, et ne peuvent être opposables aux informations légales que publie Cyde.
          De plus, ces informations pourront varier dans le temps en fonction de l'actualité de la vie de la société.
          <br />
          <br />
          Le site www.cyde.fr fait usage de liens hypertextes donnant accès à des sources d'information tierces.
          Ces contenus n'entrent pas dans le domaine de responsabilité de Cyde. Par ailleurs, Cyde ne pourra être tenu
          responsable d'aucun dommage, qu'il soit direct ou indirect, associé à l'utilisation ou l'exploitation de ces contenus.
          <br />
          <br />
          En matière de données personnelles, <br />
          - Cyde s'engage à ce que les traitements respectent les directives légales RGPD et CNIL. <br />
          En ce sens, la base légale (consentement, contrat, obligation légale, mission d’intérêt public, intérêt
          légitime, sauvegarde des intérêts vitaux) sera systématiquement qualifiée pour tout traitement. <br />
          - En cas d'interaction sur le site au travers de commentaires, l'utilisateur admet la base légale du consentement.
          Seul le pseudo est visible sur le site. Cyde recueille l'email à des fins de classement technique et de
          fonctionnement du principe de commentaires sans en faire d'autre usage. <br />
          - En cas de fourniture d'informations au travers du processus de recrutement, le site ne fonctionne qu'en
          passerelle pour messagerie et ne stocke pas les données.
          <br />
          <br />
          Les utilisateurs disposent d'un plein droit d'accès, rectification, suppression aux données personnelles les
          concernant. Afin de faire valoir ce droit, ils peuvent contacter la société Cyde tel que mentionné dans la
          rubrique contact, avec les preuves d'identité associées à leur demande. Cyde s'engage à ne pas communiquer les
          informations personnelles des utilisateurs de son site www.cyde.fr à des tiers.
          <br />
          <br />
          En matière de propriété intellectuelle, <br/>
          - Cyde a la propriété intellectuelle de l'ensemble des éléments accessibles sur le site
          (textes, images, sons, vidéos, logiciels). <br />
          - Toute reproduction, totale ou partielle des éléments du site est interdite, sauf autorisation
          explicite écrite de Cyde. <br/>
          - Toute action contrevenant à cette disposition fera l'objet de poursuites.
          <br />
          <br />
          En matière d'offres d'emploi, <br/>
          - Cyde publie toutes ses offres sur le site www.cyde.fr. Celles-ci sont classées et numérotées. <br/>
          - En cas de doute sur l'authenticité d'une offre, les utilisateurs sont invités à s'assurer qu'elles sont
          présentes sur le site et numérotées. Si tel n'est pas le cas, nous conseillons aux utilisateurs d'en
          avertir Cyde sans délai.
          <br/>
          <br/>
          En matière de juridiction, <br/>
          - Elle est soumise au droit français. <br/>
          - Le tribunal compétent sera le tribunal de Lille. <br/>
        </p>

        <h2 className="legalNotice__subtitle">C. Copyright</h2>
        <p className="legalNotice__content">© Copyright CYDE – {new Date().getFullYear()}</p>
      </div>
    </div>
  );
};

export default LegalNoticeSection;
