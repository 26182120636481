import React from "react";

import Sncf from "../../../asset/image/sncf.avif";
import {Apps, CompassCalibration, Computer} from "@mui/icons-material";
import Image from "../../../asset/image";

const ioviaSection = () => {
  return (
    <div className="productSection flex__column">
      <div className="productSection__band">
        <div className="productSection__band__content flex__column">
          <h4>Iovia</h4>
          <p>
            Découvrez IOVIA, l'application gratuite pour trouver votre métier idéal dans
            l'IT selon vos compétences et profil, disponible sur Google Play.
          </p>
          <a href="https://iovia.fr" className="btn-purple">
            En savoir plus
          </a>
        </div>
      </div>

      <div className="productSection__info flex__column">
        <h3>
          {" "}
          IOVIA : Trouvez <span className="highlight">Votre Voie</span><br/> dans l'IT
        </h3>
        <div className="info__content flex">
          <div className="info__content--img flex">
            <img src={Image.ArgIovia} alt="join us" />
          </div>
          <div className="info__content--text flex__column">
            <p>
              IOVIA révolutionne l'orientation professionnelle dans le secteur informatique. Cette application gratuite,
              disponible sur Google Play, propose une approche personnalisée pour découvrir le métier IT qui correspond
              le mieux à vos compétences et à votre profil. En répondant à des questions ciblées, vous pouvez voir comment
              votre profil s'aligne avec 50 métiers différents, vous offrant ainsi une perspective unique sur votre
              correspondance avec chaque métier. IOVIA va au-delà d'une simple application d'orientation ;
              c'est un véritable compagnon dans votre quête du métier IT idéal.
            </p>
            <div className="info__content--text__svg flex__column">
              <div className="svg__content flex__row">
                <CompassCalibration />
                <p>Orentation professionnelle</p>
              </div>
              <div className="svg__content flex__row">
                <Computer />
                <p>Métiers informatiques</p>
              </div>
              <div className="svg__content flex__row">
                <Apps />
                <p>Application gratuite</p>
              </div>
            </div>
            <button className="btn-purple" onClick={() => window.location.href="https://iovia.fr"}>En savoir plus</button>
          </div>
        </div>
      </div>

      {/*
      <div className="productSection__cta flex">
        <div className="productSection__cta--text flex__column">
          <div className="flex__row productSection__cta--text__logo">
            <img src={Sncf} alt="client" />
            <h3>Bouton éco</h3>
          </div>
          <h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h4>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore magna aliqua. Lorem ipsum dolor
            sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore magna aliqua.
          </p>
          <a href="#" className="btn-purple">
            Button
          </a>
        </div>
        <div className="productSection__cta--img"></div>
      </div>
      */}
    </div>
  );
};

export default ioviaSection;
