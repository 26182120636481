import React from 'react';
import Image from '../../../asset/image.js';

const GeneralPresentationSection = ({ onSelectTab }) => {
    const services = [
        { id: 1, name: 'NodeJS', logo: Image.NodeJS },
        { id: 2, name: 'Vue', logo: Image.Vue },
        { id: 3, name: 'React', logo: Image.React },
        { id: 4, name: 'React Native', logo: Image.ReactNative },
        { id: 5, name: 'Flutter', logo: Image.Flutter },
        { id: 6, name: 'AWS', logo: Image.AWS },
        { id: 7, name: 'DynamoDB', logo: Image.DynamoDB },
        { id: 8, name: 'Azure DevOps', logo: Image.AzureDevops },
        { id: 9, name: 'CI/CD', logo: Image.CI_CD },
    ];

    return (
        <section className="general-presentation">
            <div className="general-presentation__left">
                <h2 className="general-presentation__title">Des services de qualité, en cohésion avec les valeurs de
                    CYDE</h2>
                <p className="general-presentation__description">
                    Découvrez nos méthodologies et nos profils pour vous accompagner dans vos projets.
                </p>
                <button className="btn-purple" onClick={() => onSelectTab(1)}>Nos méthodologies</button>
                <button className="btn-purple" onClick={() => onSelectTab(2)}>Nos profils</button>
            </div>
            <div className="general-presentation__right">
                {services.map((service) => (
                    <div key={service.id} className="general-presentation__service-card">
                    <img src={service.logo} alt={service.name} className="service-card__logo" />
                        <p className="service-card__name">{service.name}</p>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default GeneralPresentationSection;