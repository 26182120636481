import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useLayoutEffect, useRef } from "react";
import "./argument.scss";

import Image from "../../../asset/image";
import {Link} from "react-router-dom";

gsap.registerPlugin(ScrollTrigger);

export default function Argument() {
  const component = useRef();
  const slider = useRef();

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      let panels = gsap.utils.toArray(".panel:not(.pn1)");

      //responsive
      let mm = gsap.matchMedia();

      mm.add("(min-width: 1030px)", () => {
        gsap.to(panels, {
          xPercent: -100 * (panels.length - 2),
          ease: "none",
          scrollTrigger: {
            trigger: slider.current,
            pin: true,
            scrub: 1,
            snap: 1 / (panels.length - 1),
            end: () => "+=" + slider.current.offsetWidth,
          },
        });

        gsap.from(".args__wrapper__progressBar", {
          scrollTrigger: {
            trigger: ".pn1",
            scrub: true,
            start: "top top",
            end: () => "+=" + slider.current.offsetWidth,
          },
          scaleY: 0,
          transformOrigin: "top center",
          ease: "none",
        });
      });
    }, component);
    return () => ctx.revert();
  });

  return (
    <div className="args" ref={component}>

      <div ref={slider} className="container">
        {/* //
            TITLE PANEL
        // */}
        <div className="panel pn1">
          <div className="args__content">
            <div className="args__content__text flex__column">
              <h1>
                Pourquoi CYDE est{" "}
                <span style={{ color: "#ffa300" }}>votre partenaire clé</span>
              </h1>
              <p>
                Choisir CYDE, c'est s'engager pour une innovation éthique et responsable, alignée sur les enjeux
                écologiques. Nous visons à promouvoir une transformation digitale consciente et durable.
              </p>
              <Link className="btn-purple" to="/construction">
                En savoir plus
              </Link>
            </div>
          </div>
        </div>
        {/* //
            FIRST PANEL
        // */}
        <div className="panel flex__column">
          <div className="args__content flex__column">
            <div className="args__content__img flex">
              <img src={Image.BannerArg1} />
            </div>
            <div className="args__content__text flex__column">
              <div className="args__content__text__title flex__row">
                <div className="bullet flex">1</div>
                <h3>Maîtrisez vos projets grâce à notre expertise</h3>
              </div>

              <div className="args__content__text__svg flex__row">
                <p>
                  Notre expertise en gestion de projet et PMO vous assure une exécution impeccable.
                  Cyde transforme vos visions en succès tangibles par une méthodologie éprouvée,
                  garantissant efficacité et qualité.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* //
            SECOND PANEL
        // */}
        <div className="panel flex__column">
          <div className="args__content flex__column">
            <div className="args__content__img flex">
              <img src={Image.BannerArg2} />
            </div>
            <div className="args__content__text flex__column">
              <div className="args__content__text__title flex__row">
                <div className="bullet flex">2</div>
                <h3>Boostez votre digitalisation avec Cyde</h3>
              </div>
              <div className="args__content__text__svg flex__row">
                <p>
                  Cyde accélère votre transformation digitale grâce à une approche agile et Lean.
                  Notre vision stratégique renforce votre compétitivité, en maximisant la valeur
                  ajoutée de vos opérations.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* //
            THIRD PANEL
        // */}
        <div className="panel flex__column">
          <div className="args__content flex__column">
            <div className="args__content__img flex">
              <img src={Image.BannerArg3} />
            </div>
            <div className="args__content__text flex__column">
              <div className="args__content__text__title flex__row">
                <div className="bullet flex">3</div>
                <h3>Optez pour l'innovation responsable</h3>
              </div>
              <div className="args__content__text__svg flex__row">
                <p>
                  Engagé en faveur du management responsable, Cyde promeut une transformation digitale écoresponsable.
                  Nos services intègrent les principes du Green-IT, valorisant le bien-être humain et
                  la préservation de l'environnement.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="args__wrapper">
          <div className="args__wrapper__progressBar"></div>
        </div>
      </div>
    </div>
  );
}
