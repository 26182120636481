/* Espace réservé pour les imports Image */
import LogoCyde from "./image/logo_cyde.svg";
import LogoFlashReport from "./image/logo_flash_report.png";
import LogoIovia from "./image/logo_iovia.png";
import LogoCoInnov from "./image/logo_co-innovation.png";
import LogoEcoOn from "./image/logo_bouton-eco_on.svg";
import LogoEcoOff from "./image/logo_bouton-eco_off.svg";
import Sncf from "./image/sncf.avif";
import Imsa from "./image/imsa.svg";
import Joinus from "./image/joinus.avif";
import ArgFlashReport from "./image/arg_flash-report.png";
import ArgIovia from "./image/arg_iovia.png";
import ArgCoInnov from "./image/arg_co-innovation.png";
import ArgEco from "./image/arg_bouton-eco.png";
import ArgRoadmap from "./image/arg_roadmap.png";

import BannerModele from "./image/banner_modele.png";
import BannerArg1 from "./image/banner_arg1.png";
import BannerArg2 from "./image/banner_arg2.png";
import BannerArg3 from "./image/banner_arg3.png";
import BannerProfile from "./image/banner_profiles.png";

import NodeJS from "./image/nodejs.svg";
import Vue from "./image/vue.svg";
import React from "./image/react.svg";
import ReactNative from "./image/react-native.svg";
import Flutter from "./image/flutter.svg";
import AWS from "./image/aws.svg";
import DynamoDB from "./image/dynamodb.svg";
import AzureDevops from "./image/azure-devops.svg";
import CI_CD from "./image/ci-cd.svg";

import AvatarAyinde from "./image/avatar_ayinde.svg";
import AvatarCyprien from "./image/avatar_cyprien.svg";
import AvatarNidal from "./image/avatar_nidal.svg";

/* Espace réservé pour les exports Image */
export default {
    LogoCyde,
    LogoFlashReport,
    LogoIovia,
    LogoCoInnov,
    LogoEcoOn,
    LogoEcoOff,
    Joinus,
    Sncf,
    Imsa,
    ArgFlashReport,
    ArgIovia,
    ArgCoInnov,
    ArgEco,
    ArgRoadmap,
    BannerModele,
    BannerArg1,
    BannerArg2,
    BannerArg3,
    BannerProfile,

    NodeJS,
    Vue,
    React,
    ReactNative,
    Flutter,
    AWS,
    DynamoDB,
    AzureDevops,
    CI_CD,

    AvatarAyinde,
    AvatarCyprien,
    AvatarNidal
};

