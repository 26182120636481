import React from 'react';
import Image from "../../../asset/image";
import {
    BiotechOutlined,
    BuildCircleOutlined,
    DesignServicesOutlined,
    IntegrationInstructionsOutlined,
    LightbulbOutlined
} from "@mui/icons-material";

const WorkingMethodSection = () => {
    const methods = [
        {
            id: 1,
            title: 'Idéation',
            description: "Processus créatif combinant génération d'idées, filtrage stratégique, et évaluation par IA pour aligner projets et valeurs d'entreprise. Un mélange d'émulation et de technologie pour des solutions innovantes.",
            bulletPoints: [
                'L\'émulation pour trouver des idées',
                'Les stratégies de filtrage/recentrage',
                'La mise en correspondance multiple (valeurs, projet, modèle)',
                'Les évaluations compléments augmentées (IA et Vianeo)',
            ],
            icon: <LightbulbOutlined />,
        },
        {
            id: 2,
            title: 'Conception',
            description: "Définition structurée de l'architecture produit et application de patterns à différents niveaux pour préparer une industrialisation efficace. Un équilibre entre innovation et pragmatisme.",
            bulletPoints: [
                'Les choix de structure',
                'L\'architecture des produits',
                'Le fonctionnement par patterns à différents niveaux',
                'L\'objectif de structurer propre pour industrialiser ensuite',
            ],
            icon: <DesignServicesOutlined />,
        },
        {
            id: 3,
            title: 'Réalisation',
            description: "Engagement pour le Clean Code, valorisation des revues croisées, et pratique du Pair Programming pour une exécution de haute qualité. Excellence technique au cœur de la réalisation.",
            bulletPoints: [
                'L\'importance du Clean Code',
                'Les revues croisées',
                'Le Pair Programming',
            ],
            icon: <BuildCircleOutlined />,
        },
        {
            id: 4,
            title: 'Tests',
            description: "Approche segmentée (POC, MVP) intégrant des Releases Continues pour minimiser les risques, appuyée par des démos régulières. Qualification rigoureuse pour des solutions fiables.",
            bulletPoints: [
                'La stratégie de qualification',
                'La segmentation (POC, MVP) couplée au Release Continues pour réduire les risques',
                'Les démos en continu',
            ],
            icon: <BiotechOutlined />,
        },
        {
            id: 5,
            title: 'Méthode',
            description: "Synergie des méthodes Agile, Lean, et SAFe enrichies par une comitologie adaptative et une ritualisation des processus. Flexibilité et amélioration continue pour exceller.",
            bulletPoints: [
                'L\'inspiration des méthodes existantes Agile, Lean, SAFe, Innovation',
                'La comitologie adaptative',
                'Ritualisation',
                'L\'approche Inspect & Adapt',
            ],
            icon: <IntegrationInstructionsOutlined />,
        },
    ];

    return (
        <section className="working-method">
            <h2 className="working-method__title">Notre façon de travailler</h2>
            <div className="working-method__content">
                {methods.map((method) => (
                    <>
                        <div key={method.id}
                             className="method-card">
                            <div className="method-card__header">
                                {React.cloneElement(method.icon, { className: "method-card__icon" })}
                                <h3 className="method-card__title">{method.title}</h3>
                            </div>
                            <div className="method-card__content">
                                <p className="method-card__description">{method.description}</p>
                                {/*
                                <ul className="method-card__list">
                                    {method.bulletPoints.map((point, index) => (
                                        <li key={index} className="method-card__list-item">{point}</li>
                                    ))}
                                </ul>
                                */}
                            </div>
                        </div>
                    </>
                ))}
            </div>
        </section>
    );
};

export default WorkingMethodSection;
