import React, { useEffect, useState } from "react";
import "./contact.scss";
import MailIcon from "@mui/icons-material/Mail";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { MenuItem, TextField } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

function ContactSection() {
  const appTitle = "SITE CYDE";

  const subjects = [
    {
      value: "DEMONSTRATION",
      label: "Démonstration",
    },
    {
      value: "PRECOMMANDE",
      label: "Précommande",
    },
    {
      value: "SUGGESTIONS",
      label: "Suggestions",
    },
    {
      value: "AUTRE",
      label: "Autre",
    },
  ];

  const [formData, setFormData] = useState({
    app: appTitle,
    surname: "",
    name: "",
    email: "",
    phone: "",
    subject: "",
    content: "",
  });

  const sendMessage = async () => {
    try {
      const response = await fetch(process.env.CONTACT_API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.status === 200) {
        console.log("Message envoyé avec succès:", data.message);
      } else {
        console.error("Erreur lors de l'envoi:", data.error);
      }
    } catch (error) {
      console.error("Erreur:", error);
    }
  };

  return (
    <div>
      <div className="main-contact">
        <div className="contact">
          <div className="contact__card">
            <h1>Contactez nous</h1>
            <p className="contact__card__title">
              Nous sommes ravis de vous entendre ! N'hésitez pas à nous
              contacter pour toute question, demande d'information ou
              collaboration
            </p>
            <div className="contact__card__text">
              <div className="svg">
                <MailIcon />
              </div>
              <p>contact@cyde.fr</p>
            </div>
            <div className="contact__card__text">
              <div className="svg">
                <LocationOnIcon />
              </div>
              <p>
                679 Avenue de la République, <br /> Lille 59800 France
              </p>
            </div>
            <div className="contact__card__joinus">
              <p>Rejoignez-nous sur les réseaux sociaux !</p>
            </div>
            <div className="contact__card__social">
              <a href="#" className="svg">
                <FacebookIcon />
              </a>
              <a href="#" className="svg">
                <XIcon />
              </a>
              <a href="#" className="svg">
                <LinkedInIcon />
              </a>
            </div>
          </div>
          <div className="contact__form">
            <h2>Besoin de nous dire quelque chose ?</h2>
            <form>
              <TextField
                className="contact__form__input"
                required
                id="surname"
                label="Nom"
                value={formData.surname}
                onChange={(e) =>
                  setFormData({ ...formData, surname: e.target.value })
                }
                variant="standard"
              />
              <TextField
                className="contact__form__input"
                required
                id="name"
                label="Prénom"
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                variant="standard"
              />
              <TextField
                className="contact__form__input"
                required
                id="email"
                label="Email"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                variant="standard"
              />
              <TextField
                className="contact__form__input"
                id="phone"
                label="Numéro de téléphone"
                value={formData.phone}
                onChange={(e) =>
                  setFormData({ ...formData, phone: e.target.value })
                }
                variant="standard"
              />
              <TextField
                className="contact__form__select"
                id="subject"
                select
                label="À quel sujet ?"
                defaultValue="DEMONSTRATION"
                value={formData.subject}
                onChange={(e) =>
                  setFormData({ ...formData, subject: e.target.value })
                }
                variant="standard"
              >
                {subjects.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                className="contact__form__input"
                id="body"
                label="Message"
                placeholder="Ecrivez votre message ici..."
                multiline
                rows={4}
                value={formData.content}
                onChange={(e) =>
                  setFormData({ ...formData, content: e.target.value })
                }
                variant="standard"
              />
              <br />
              <br />
              <button onClick={sendMessage} className="contact__form__submit">
                Envoyez votre message
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactSection;
