import React, {useRef, useState} from 'react';
import GeneralPresentationSection from "./GeneralPresentationSection/GeneralPresentationSection";
import WorkingMethodSection from "./WorkingMethodSection/WorkingMethodSection";
import ProfilePresentationSection from "./ProfileSection/ProfileSection";
import './services.scss';
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

const Services = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    const workingMethodRef = useRef(null);
    const profilePresentationRef = useRef(null);

    const scrollToSection = (ref) => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const handleTabSelect = (index) => {
        switch(index) {
            case 1:
                setSelectedTab(1);
                scrollToSection(workingMethodRef);
                break;
            case 2:
                setSelectedTab(2);
                scrollToSection(profilePresentationRef);
                break;
            default:
                break;
        }
    };

    return (
        <div className="services">
            <div
                className={
                    selectedTab === 0 ? "indicator--hidden" : "indicator-up flex"
                }
                onClick={() => {
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                    });
                    setSelectedTab(0)
                }}
            >
                <ArrowUpwardIcon/>
            </div>
            <GeneralPresentationSection onSelectTab={handleTabSelect}/>
            <div ref={workingMethodRef}><WorkingMethodSection/></div>
            <div ref={profilePresentationRef}><ProfilePresentationSection/></div>
        </div>
    );
};

export default Services;
