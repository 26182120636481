import React from "react";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import "./info.scss";
import Image from "../../../asset/image";
import {Link} from "react-router-dom";

const info = () => {
  return (
    <div className="info flex">
      <div className="info__card flex__row">
        <div className="info__card__text flex__column">
          <div className="info__card__text__content flex__column">
            <h1>
              Alliance ESN-Éditeur pour un
              <span className="highlight">
                {" "}
                Management Responsable Innovant
              </span>
            </h1>
            <p>
              Chez Cyde, Nous Réinventons le Consulting avec une Synergie Unique
              entre Conseil, Roadmap Éditoriale, et R&D Innovante
            </p>
            <div className="info__card__text__content__svg flex__column">
              <div className="svg__content flex__row">
                <AccountBalanceIcon />
                <p>
                  Pour nos clients : Explorez nos solutions innovantes pour
                  optimiser temps et budget grâce à une approche sur mesure
                  associant technologie et innovation à votre transformation
                  digitale, tout en favorisant un management responsable.
                </p>
              </div>
              <div className="svg__content flex__row">
                <AccountBalanceIcon />
                <p>
                  Pour nos consultants : Bénéficiez d'un soutien exceptionnel et
                  enrichissez votre expertise via notre portfolio d'innovations
                  et d'expériences en R&D. Nous consolidons notre leadership
                  dans l'élaboration de solutions IT responsables.
                </p>
              </div>
              <Link className="btn-purple" to="/construction">
                En savoir plus
              </Link>
            </div>
          </div>
          {/* <button className="btn-purple">En savoir plus</button> */}
        </div>
        <div className="info__card__img">
          <img src={Image.BannerProfile} alt="join us" />
        </div>
      </div>

      {/* <div className="info__content flex__column">
        <div className="info__content__text flex__column">
          <h1>
            {" "}
            Lorem ipsum <span className="highlight">dolor sit amet</span>,<br />
            consectetur aliqua
          </h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore magna aliqua. Lorem ipsum dolor
            sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore magna aliqua
          </p>
          <div className="info__content__text__svg flex__column">
            <div className="svg__content flex__row">
              <AccountBalanceIcon />
              <p>Conseil</p>
            </div>
            <div className="svg__content flex__row">
              <AccountBalanceIcon />
              <p>Innovation</p>
            </div>
            <div className="svg__content flex__row">
              <AccountBalanceIcon />
              <p>Valeur</p>
            </div>
          </div>
        </div>
        <button className="btn-purple">En savoir plus</button>
      </div>
      <img src={Joinus} alt="join us" /> */}
    </div>
  );
};

export default info;
