import React from "react";
import "./footer.scss";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Divider, Stack } from "@mui/material";
import LogoCyde from "../../asset/image/logo_cyde.svg";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="footer flex__column">
      <h2>Cyde</h2>
      <p>
        Cyde se démarque en tant qu'ESN-éditeur, s'engageant fermement dans le green IT et le management responsable.
        Notre approche globale vise à intégrer des pratiques durables et éthiques dans toutes nos activités,
        des solutions logicielles innovantes à la gestion de projets, en passant par le développement personnel et
        professionnel. Découvrez comment notre engagement envers un avenir durable et responsable façonne chaque aspect
        de notre travail, reflétant notre conviction que la technologie doit être au service d'un monde meilleur.
      </p>
      <div className="footer__panel flex">
        <div className="footer__panel__left">
          <img src={LogoCyde} alt="Logo" />
        </div>
        <div className="footer__panel__right">
          <div className="panel__links">
            <h2>LIENS RAPIDES</h2>
            <div className="panel__links__content flex__row">
              <ul className="content__list flex__column">
                <li className="list__item">
                  <Link to="/">Accueil</Link>
                </li>
                <li className="list__item">
                  <Link to="/produits">Nos produits</Link>
                </li>
                <li className="list__item">
                  <Link to="/services">Nos services</Link>
                </li>
                <li className="list__item">
                  <Link to="/construction">À propos</Link>
                </li>
              </ul>
              <ul className="content__list flex__column">
                {/*
                <li className="list__item">
                  <Link to="/">Blog</Link>
                </li>
                <li className="list__item">
                  <Link to="/fonctionnalites">Recrutement</Link>
                </li>
                */}
                <li className="list__item">
                  <Link to="/contact">Contact</Link>
                </li>
                <li className="list__item">
                  <Link to="/mentions-legales">Mentions légales</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="social">
            <Stack
              direction="row"
              divider={
                <Divider orientation="vertical" color={"black"} flexItem />
              }
              spacing={3}
            >
              <a
                className="social__link"
                href="https://www.linkedin.com/company/cyde-sas/"
              >
                <LinkedInIcon />
              </a>
              <a
                className="social__link"
                href="https://twitter.com/cyde_sas?s=21"
              >
                <XIcon />
              </a>
              <a
                className="social__link"
                href="https://www.facebook.com/societe.cyde"
              >
                <FacebookIcon />
              </a>
            </Stack>
          </div>
        </div>
      </div>
      <Divider className="footer__divider"></Divider>
      <div className="footer__copyright">
        <p className="footer__copyright__text">
          © {new Date().getFullYear()} - CYDE - Tous droits réservés
        </p>
      </div>
    </footer>
  );
}

export default Footer;
