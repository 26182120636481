import React from "react";
import Sncf from "../../../asset/image/sncf.avif";
import {GroupWork, Lightbulb, People} from "@mui/icons-material";
import Image from "../../../asset/image";

const coInnovSection = () => {
  return (
    <div className="productSection flex__column">
      <div className="productSection__band">
        <div className="productSection__band__content flex__column">
          <h4>Co-innovation</h4>
          <p>
            Co-Innovation, votre plateforme de co-création pour partager idées
            et connecter des acteurs autour de projets d'innovation ambitieux.
          </p>
        </div>
      </div>

      <div className="productSection__info flex_column">
        <h3>
          {" "}
          Co-Innovation : Le Pouvoir de la <span className="highlight">Co-Création</span> au Service de l'Innovation
        </h3>
        <div className="info__content flex">
          <div className="info__content--img flex">
            <img src={Image.ArgCoInnov} alt="join us" />
          </div>
          <div className="info__content--text flex__column">
            <p>
              Co-Innovation est une plateforme unique en son genre, spécialement conçue pour favoriser la co-création,
              le partage d'idées et la mise en relation entre différents acteurs animés par le désir de porter des
              projets d'innovation. En mettant l'accent sur la collaboration et l'échange, Co-Innovation ouvre de
              nouvelles perspectives pour ceux qui cherchent à réaliser des projets ambitieux en tirant parti de la
              diversité des compétences et des visions. C'est l'endroit idéal pour ceux qui souhaitent aller au-delà
              des frontières traditionnelles de l'innovation.
            </p>
            <div className="info__content--text__svg flex__column">
              <div className="svg__content flex__row">
                <GroupWork />
                <p>Collaboration</p>
              </div>
              <div className="svg__content flex__row">
                <Lightbulb />
                <p>Innovation</p>
              </div>
              <div className="svg__content flex__row">
                <People />
                <p>Idéation</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*
      <div className="productSection__cta flex">
        <div className="productSection__cta--text flex__column">
          <div className="flex__row productSection__cta--text__logo">
            <img src={Sncf} alt="client" />
            <h3>Co-innovation</h3>
          </div>
          <h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h4>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore magna aliqua. Lorem ipsum dolor
            sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore magna aliqua.
          </p>
          <a href="#" className="btn-purple">
            Button
          </a>
        </div>
        <div className="productSection__cta--img"></div>
      </div>
      */}
    </div>
  );
};

export default coInnovSection;
