import React from "react";
import {Link} from "react-router-dom";

import "./hero.scss";

function Hero() {
    return (
        <>
            <div className="hero">
                <div className="hero__wrapper">
                    <div className="hero__section">
                        <h1>Avec CYDE, réinventez le futur de l'innovation durable</h1>
                        <h3>
                            Embarquez dans une aventure où le modèle ESN-éditeur de CYDE redéfinit les contours de
                            l'innovation et de la durabilité. Nous fusionnons expertise technique et vision responsable
                            pour offrir des solutions digitales pionnières. Chez CYDE, l'innovation s'accompagne d'un
                            engagement ferme envers le management responsable, sculptant un avenir où technologie et
                            éthique marchent main dans la main. Découvrez comment notre approche unique transforme les
                            défis d'aujourd'hui en succès de demain.
                        </h3>
                        <div className="hero__section__btn">
                            <Link className="btn-purple" to="/produits">
                                Nos produits
                            </Link>
                            <Link className="btn-purple" to="/services">
                                Nos services
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hero--img"></div>
        </>
    );
}

export default Hero;
