import React, { useEffect } from "react";
import { Services } from "../section/index";

const ServicesPage = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, []);

    return <Services />;
};

export default ServicesPage;
