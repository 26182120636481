import React from "react";
import Sncf from "../../../asset/image/sncf.avif";
import {EnergySavingsLeaf, Public, Recycling} from "@mui/icons-material";
import Image from "../../../asset/image";


const ecoSection = () => {
  return (
    <div className="productSection flex__column">
      <div className="productSection__band">
        <div className="productSection__band__content flex__column">
          <h4>Bouton éco</h4>
          <p>
            Réduisez votre empreinte écologique avec le bouton éco-responsable
            de Cyde, optimisant votre navigation sans compromettre la qualité.
          </p>
        </div>
      </div>

      <div className="productSection__info flex__column">
        <h3>
          {" "}
          Bouton <span className="highlight">Éco-Responsable</span> de Cyde : <br/> Naviguez <span className="highlight">Vert</span>
        </h3>
        <div className="info__content flex">
          <div className="info__content--img flex">
            <img src={Image.ArgEco} alt="join us" />
          </div>
          <div className="info__content--text flex__column">
            <p>
              Avec le bouton éco-responsable de Cyde, faire un geste pour la planète n'a jamais été aussi simple.
              En choisissant de vous passer de certains éléments graphiques sur les sites web, vous pouvez
              significativement réduire votre consommation de bande passante et, par extension, votre empreinte
              écologique. Cette initiative de Cyde marque un pas important vers une navigation internet plus durable,
              sans pour autant compromettre la qualité de votre expérience en ligne.
              Adoptez le bouton éco-responsable et contribuez à un internet plus vert.
            </p>
            <div className="info__content--text__svg flex__column">
              <div className="svg__content flex__row">
                <Recycling />
                <p>Éco-responsabilité</p>
              </div>
              <div className="svg__content flex__row">
                <EnergySavingsLeaf />
                <p>Green-IT</p>
              </div>
              <div className="svg__content flex__row">
                <Public />
                <p>Durabilité</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*
      <div className="productSection__cta flex">
        <div className="productSection__cta--text flex__column">
          <div className="flex__row productSection__cta--text__logo">
            <img src={Sncf} alt="client" />
            <h3>Bouton éco</h3>
          </div>
          <h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h4>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore magna aliqua. Lorem ipsum dolor
            sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore magna aliqua.
          </p>
          <a href="#" className="btn-purple">
            Button
          </a>
        </div>
        <div className="productSection__cta--img"></div>
      </div>
      */}
    </div>

  );
};

export default ecoSection;
