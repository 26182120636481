import React from "react";
import { createBrowserRouter } from "react-router-dom";
import {
  LandingPage,
  OurProductPage,
  ErrorPage,
  ContactPage,
  LegalNoticePage,
  ServicesPage, ConstructionPage,
} from "../pages/index";
import Layout from "../layout/mainLayout";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Layout>
        <LandingPage />
      </Layout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "produits",
    element: (
      <Layout>
        <OurProductPage />
      </Layout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "services",
    element: (
      <Layout>
        <ServicesPage />
      </Layout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "contact",
    element: (
      <Layout>
        <ContactPage />
      </Layout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "mentions-legales",
    element: (
      <Layout>
        <LegalNoticePage />
      </Layout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "construction",
    element: (
      <Layout>
        <ConstructionPage />
      </Layout>
    ),
  }
]);

export default router;
