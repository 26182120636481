import React, { useEffect } from "react";
import {
  Hero,
  Client,
  Info,
  Presentation,
  Argument,
  Bandeau,
} from "../section/index";

function LandingPage() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div>
      <Hero />
      <Client />
      <Info />
      <Presentation />
      <Argument />
      <Bandeau />
    </div>
  );
}

export default LandingPage;
